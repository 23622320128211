<template>
  <div class="sidebar">
    <div class="sidebar-links">
      <b-tooltip
        v-for="item in sidebarContent"
        :key="item.label"
        :label="item.label"
        :active="!showWorkspaceList"
        position="is-right"
        type="is-dark"
        append-to-body
      >
        <router-link :to="item.route">
          <div
            class="sidebar-links-link"
            :class="{
              selected: $route.name === item.routeName,
              'is-home':
                item.label === 'Home' &&
                currentWorkspace.image &&
                !homeErrored &&
                homeLoaded
            }"
            @contextmenu="e => handleRightClick(item, e)"
          >
            <img
              :src="
                item.label === 'Home' && currentWorkspace.image && !homeErrored
                  ? currentWorkspace.image
                  : require(`@/assets/icons/${item.icon}.svg`)
              "
              alt=""
              class="sidebar-links-link-icon"
              @error="() => handleError(item)"
              @load="() => handleLoad(item)"
            />
            <p v-if="item.badge" class="sidebar-links-link-badge">
              {{ item.badge }}
            </p>
          </div>
        </router-link>
      </b-tooltip>
      <div class="sidebar-links-bottom">
        <ImpersonationMenu v-if="isUman" />
        <UserMenu />
      </div>
    </div>
    <WorkspaceSelect
      v-if="showWorkspaceList"
      @hide="() => (showWorkspaceList = false)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WorkspaceSelect from '../organisms/WorkspaceSelect.vue'
import UserMenu from '../organisms/UserMenu.vue'
import ImpersonationMenu from '../organisms/ImpersonationMenu.vue'

export default {
  name: 'SegmentedWebAppSidebar',
  components: {
    WorkspaceSelect,
    UserMenu,
    ImpersonationMenu
  },
  props: {
    workspace: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    homeLoaded: false,
    homeErrored: false,
    showWorkspaceList: false
  }),
  computed: {
    ...mapGetters([
      'currentWorkspace',
      'isUman',
      'showCollectSlides',
      'canWriteChats',
      'canWriteMembers',
      'canWriteStories',
      'canWriteOri',
      'canReadOri',
      'canWriteLabels',
      'canUseSearch',
      'canUseProspecting',
      'canReadProspectingLists',
      'settingsTabs',
      'workspaces'
    ]),
    sidebarContent() {
      return [
        {
          label: 'Home',
          icon: 'home-alt',
          routeName: 'home',
          route: {
            name: 'home',
            params: {
              workspace_id: this.$route.params.workspace_id
            },
            query: {}
          }
        },
        ...(this.canUseSearch
          ? [
              {
                label: 'Search',
                icon: 'search',
                routeName: 'search',
                route: { name: 'search' }
              }
            ]
          : []),
        ...(this.canWriteStories
          ? [
              {
                label: 'Your meetings',
                icon: 'calendar',
                routeName: 'meetings',
                route: { name: 'meetings' }
              }
            ]
          : []),
        ...(this.canWriteChats
          ? [
              {
                label: 'PortfolioGPT',
                icon: 'chat',
                routeName: 'chat',
                route: { name: 'chat' },
                badge: 'NEW'
              }
            ]
          : []),
        ...(this.canWriteOri || this.canReadOri
          ? [
              {
                label: 'Portfolio',
                icon: 'compass',
                routeName: 'portfolio',
                route: {
                  name: 'portfolio',
                  params: { ori_type: 'offerings' }
                }
              }
            ]
          : []),
        ...(this.canUseProspecting
          ? [
              {
                label: 'Prospecting',
                icon: 'prospect',
                routeName: 'prospecting',
                route: { name: 'prospecting' }
              }
            ]
          : []),
        ...(this.canReadProspectingLists
          ? [
              {
                label: 'Accounts',
                icon: 'users',
                routeName: 'accounts',
                route: { name: 'accounts' }
              }
            ]
          : []),
        ...(this.showCollectSlides && this.canUseSearch
          ? [
              {
                label: 'Your presentations',
                icon: 'presentation',
                routeName: 'presentations',
                route: { name: 'presentations' }
              }
            ]
          : []),
        ...(this.canWriteMembers
          ? [
              {
                label: 'User analytics',
                icon: 'pie-chart',
                routeName: 'analytics',
                route: { name: 'analytics' }
              }
            ]
          : []),
        ...(this.canWriteLabels
          ? [
              {
                label: 'Curation items',
                icon: 'curate',
                routeName: 'curation',
                route: { name: 'curation' }
              }
            ]
          : []),
        ...(this.settingsTabs.length
          ? [
              {
                label: 'Workspace settings',
                icon: 'settings',
                routeName: 'ws-settings',
                route: {
                  name: 'ws-settings',
                  params: { tab: [this.settingsTabs[0]] }
                }
              }
            ]
          : [])
      ]
    }
  },
  methods: {
    handleError(item) {
      if (item.label === 'Home') this.homeErrored = true
    },
    handleLoad(item) {
      if (item.label === 'Home') this.homeLoaded = true
    },
    handleRightClick(item, event) {
      if (!(item.label === 'Home' && this.workspaces?.length)) return
      event.preventDefault()
      if (this.showWorkspaceList) {
        this.showWorkspaceList = false
        return
      }
      this.showWorkspaceList = true
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  height: 100vh;
  width: 65px;
  min-width: 65px;
  background: white;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &-links {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    flex: 1;
    padding: 1rem 0;
    align-items: center;

    &-link {
      position: relative;
      cursor: pointer;
      border-radius: 4px;
      height: 2.5rem;
      width: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;

      &:hover {
        background: #f1f2f3;
      }

      &.is-home {
        &.selected {
          border: 1px solid $primary;
          background: rgba($primary, 0.1);
        }

        & > .sidebar-links-link-icon {
          border-radius: 2px;
        }
      }

      &:not(.is-home) {
        &.selected {
          & > .sidebar-links-link-icon {
            filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
              saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
          }
        }
      }

      &-icon {
        height: 1.5rem;
      }

      &-badge {
        position: absolute;
        top: 25%;
        right: 10%;
        transform: translateY(-50%) translateX(50%);
        font-size: 0.75rem;
        font-weight: 600;
        color: white;
        background: red;
        border: 2px solid white;
        border-radius: 999rem;
        padding: 0 0.25rem;
        white-space: nowrap;
      }
    }

    &-bottom {
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;
      align-items: center;
      margin-top: auto;
    }
  }
}
</style>
